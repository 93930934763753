<template>
  <div>
    <div class="well_frame">
      <el-tabs v-model="tabActiveName" class="tabs_demo">

        <!-- 会员卡 -->
        <el-tab-pane label="会员卡" name="vip_card" v-loading="vipUserList.loading">
          <el-radio-group v-model="vipCardTabActiveName" style="margin-bottom:20px;">
            <el-radio-button label="normal">未过期</el-radio-button>
            <el-radio-button label="expired">已过期</el-radio-button>
          </el-radio-group>

          <el-row :gutter="20" v-if="vipUserList.pager.total > 0">

            <el-col :span="12"  v-for="vip_card in vipUserList.data">

              <VipCard :vip_card="vip_card" :disabled="vipCardTabActiveName == 'expired'"
                       :price_visible="false" :btn_buy="false"
                       :tag_stop="!vip_card.is_enabled"
                       :expired_text_color="vip_card.expired_text_color"
                       :expired_at="vip_card.expired_at"
                       @show-detail-modal="showDetailModal('vip', vip_card)"></VipCard>

            </el-col>

          </el-row>

          <el-empty description="暂无会员卡" v-if="vipUserList.pager.total == 0 "></el-empty>

          <Pagination v-if="vipUserList.pager.total > 0"
                      :pager="vipUserList.pager"
                      @getPager="getPagerVipUser">
          </Pagination>

        </el-tab-pane>

        <!-- 优惠券 -->
        <el-tab-pane label="优惠券" name="coupons"  v-loading="couponsUserList.loading">
          <div class="clearfix">
            <el-radio-group v-model="couponTabActiveName" style="margin-bottom:20px;">
              <el-radio-button label="received">未使用</el-radio-button>
              <el-radio-button label="ordered">使用中</el-radio-button>
              <el-radio-button label="used">已使用</el-radio-button>
              <el-radio-button label="expired">已过期</el-radio-button>
            </el-radio-group>

            <el-form class="pull-right"
                     :model="couponsExchangeFrm" :rules="rules" ref="couponsExchangeFrm">
              <el-form-item label="兑换码：" label-width="100px" prop="exchangeCode">
                <el-row :gutter="10">
                  <el-col :span="16">
                    <el-input placeholder="输入优惠券兑换码" size="small"
                              v-model="couponsExchangeFrm.exchangeCode"/>
                  </el-col>
                  <el-col :span="7">
                    <el-button type="primary" size="small"
                               @click="exchangeCouponsUser">领取</el-button>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </div>

          <el-row :gutter="20" v-if="couponsUserList.pager.total > 0" >

            <el-col :span="12" v-for="coupons in couponsUserList.data">

              <Coupons :coupons="coupons"
                       :disabled="couponTabActiveName == 'expired'"
                       :tag_stop="!coupons.is_enabled"
                       :user_limit_visible="false" :btn_issue="false"
                       :expired_at="coupons.expired_at"
                       :expired_text_color="coupons.expired_text_color"
                       :related_order="coupons.related_order"
                       @show-related-order-detail="showRelatedOrderDetail(coupons.related_order)"
                       @show-detail-modal="showDetailModal('coupons', coupons)"></Coupons>

            </el-col>

          </el-row>

          <el-empty description="暂无优惠券" v-if="couponsUserList.pager.total == 0"></el-empty>

          <Pagination v-if="couponsUserList.pager.total > 0"
                      :pager="couponsUserList.pager"
                      @getPager="getPagerCouponsUsers">
          </Pagination>

        </el-tab-pane>

      </el-tabs>
    </div>

    <DiscountDetailModal :title="detailModal.title"
                         v-model:visible="detailModal.visible"
                         :data="detailModal.data"></DiscountDetailModal>
  </div>
</template>

<script>
  import { getPagerOffsetLimit } from 'utils/common'
  import Pagination from 'components/common/Pagination'
  import DiscountDetailModal from "components/settlement/DiscountDetailModal"
  import VipCard from "components/settlement/VipCard"
  import Coupons from "components/settlement/Coupons"
  import {fmtVIPCardInfo} from 'api/settlement/vip_card/admin'
  import {listVipUser} from 'api/settlement/vip_card/user'
  import {fmtCouponsInfo} from 'api/settlement/coupons/admin'
  import {fmtOrderInfo} from 'api/settlement/order/admin'
  import {expired_delta_days_conf} from 'api/settlement/card_util'
  import {exchangeCoupons, listCouonsUser} from 'api/settlement/coupons/user'
  import { db_time_to_local_fmt } from 'utils/timeutils'
  import {getVipUserInstance, getCouponsUserInstance} from 'api/settlement/center_card'
  export default {
    components: {
      Pagination,
      DiscountDetailModal,
      VipCard,
      Coupons
    },
    data() {
      return {
        tabActiveName: 'vip_card',
        vipCardTabActiveName: 'normal',
        couponTabActiveName: 'received',
        vipUserList: {
          loading: true,
          data: [],
          pager: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
          }
        },
        couponsUserList: {
          loading: true,
          data: [],
          pager: {
            currentPage: 1,
            pageSize: 10,
            total: 0,
          }
        },
        detailModal: {
          visible: false,
          title: '',
          data: {
            'name': '-',
            'desc': '-',
            'discount': '-',
            'num': '-',
            'expire': '-',
            'rights': '-',
            'get': '-',
            'use': '-',
            'apply': '-'
          }
        },
        couponsExchangeFrm: {
            exchangeCode: ''
        },
        rules: {
          exchangeCode: [
            {required: true, message: '请输入优惠券兑换码', trigger: 'change'},
            {min: 12, max: 20, message: '长度在 12~20 个字符内', trigger: 'change'},
          ],
        }
      }
    },
    created() {

      if (this.$route.params.tabName != undefined) {
        this.tabActiveName = this.$route.params.tabName;
      } else {
          this.tabActiveName = 'vip_card';
      }
    },
    mounted() {
        switch(this.tabActiveName){
          case 'vip_card': {
              this.loadVipUsers();
          } break;
          case 'coupons': {
              this.loadCouponsUsers();
          } break;
        }
    },
    methods: {
      async showDetailModal(type, data) {

        const that = this;

        switch (type) {
          case 'vip': {


            let filled_vip = await getVipUserInstance(data.vip_user_uuid, ()=>{
                that.vipUserList.loading = true;
            }, ()=>{
                that.vipUserList.loading = false;
            });
            console.log(`filled_vip: `, filled_vip);
            data = filled_vip;

            console.log(data);

            this.detailModal.discountType = 'vip';
            this.detailModal.title = `会员卡 ${data.name} `;

            this.detailModal.data = {
              name: data.name,
              desc: data.desc.trim().length == 0 ? '-' : data.desc.trim(),
              discount: data.discount_text,
              promote_price: data.promote_price,
              origin_price: data.origin_price,
              expired_desc: data.expired_desc,
              rights_tips: data.rights_tips.trim().length == 0 ? '-' : data.rights_tips.trim(),
              receive_tips: data.receive_tips.trim().length == 0 ? '-' : data.receive_tips.trim(),
              instructions: data.instructions.trim().length == 0 ? '-' : data.instructions.trim(),
              cur_scope_detail: data.cur_scope_detail,
              cur_scope: data.cur_scope,
            }

            console.log(data);

          } break;
          case 'coupons': {

            let filled_coupons_user = await getCouponsUserInstance(data.coupons_user_uuid, ()=>{
                that.couponsUserList.loading = true;
            }, ()=>{
                that.couponsUserList.loading = false;
            });
            console.log(`filled_coupons_user: `, filled_coupons_user);

            data = filled_coupons_user;

              this.detailModal.discountType = 'coupons';
              this.detailModal.title = `优惠券 ${data.name} `;

            this.detailModal.data = {
              name: data.name,
              desc: data.desc.trim().length == 0 ? '-' : data.desc.trim(),
              discount: `${data.discount_strength}${data.discount_text},${data.discount_limit}`,
              expired_desc: data.expired_desc,
              user_limit: data.user_limit,
              rights_tips: data.rights_tips.trim().length == 0 ? '-' : data.rights_tips.trim(),
              receive_tips: data.receive_tips.trim().length == 0 ? '-' : data.receive_tips.trim(),
              instructions: data.instructions.trim().length == 0 ? '-' : data.instructions.trim(),
              cur_scope_detail: data.cur_scope_detail,
              cur_scope: data.cur_scope,
            }

          } break;
        }

        console.log(this.detailModal.data);

        this.detailModal.visible = true;
      },
      getPagerVipUser(pager) { //获取分页变化后数据
        this.loadVipUsers();
      },
      getPagerCouponsUsers(pager) { //获取分页变化后数据
        this.loadCouponsUsers();
      },
      loadVipUsers(){

          const that = this;

          let pager_offset_limit = getPagerOffsetLimit(this.vipUserList.pager);

          let vip_user_filters = {
              state: this.vipCardTabActiveName ,
              limit: pager_offset_limit['limit'],
              offset: pager_offset_limit['offset']
          };

          this.vipUserList.loading = true;

          listVipUser(vip_user_filters).then(result=>{

              this.vipUserList.loading = false;

              if (result.succeed){

                  that.vipUserList.data = [];

                  result.data.forEach(item=>{

                      let filled_item = fmtVIPCardInfo(item.vip_card);
                      filled_item.vip_user_uuid = item.uuid;
                      filled_item.expired_at = db_time_to_local_fmt(item.rights_end, 'yyyy-MM-dd');
                      filled_item.is_enabled = item.is_enabled && filled_item.state_text !=='停用';

                      let delta_conf = expired_delta_days_conf(item.rights_end);
                      filled_item.expired_days = delta_conf.delta_days;
                      filled_item.expired_text = delta_conf.delta_text;
                      filled_item.expired_text_color = delta_conf.text_color;

                      that.vipUserList.data.push(filled_item);
                  });

                  that.vipUserList.pager.total = result.count;

              } else {
                that.$alert(`${result.error_msg}`, '提示', {
                  confirmButtonText: '确定'
                });
              }

          }).catch(err => {

              this.vipUserList.loading = false;

            that.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });

      },
      loadCouponsUsers(){

        const that = this;

        let pager_offset_limit = getPagerOffsetLimit(this.couponsUserList.pager);

        let coupons_user_filters = {
          state: this.couponTabActiveName,
          limit: pager_offset_limit['limit'],
          offset: pager_offset_limit['offset']
        };


        this.couponsUserList.loading = true;
        listCouonsUser(coupons_user_filters).then(result => {

          this.couponsUserList.loading = false;

          if (result.succeed) {

            that.couponsUserList.data = [];

            result.data.forEach(item => {

              let filled_item = fmtCouponsInfo(item.coupons);
              filled_item.coupons_user_uuid = item.uuid;
              filled_item.expired_at = db_time_to_local_fmt(item.time_end, 'yyyy-MM-dd');
              filled_item.is_enabled = item.is_enabled && filled_item.state_text !=='停用';

              let delta_conf = expired_delta_days_conf(item.time_end);
              filled_item.expired_days = delta_conf.delta_days;
              filled_item.expired_text = delta_conf.delta_text;
              filled_item.expired_text_color = delta_conf.text_color;

              if (item.order_info != null){
                  filled_item.related_order = fmtOrderInfo(item.order_info);
              }

              that.couponsUserList.data.push(filled_item);
            });

            that.couponsUserList.pager.total = result.count;

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {

          this.couponsUserList.loading = false;

          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      showRelatedOrderDetail(order_info){

        if (order_info && order_info.uuid) {
          this.goPath('/center/order');
        }

      },
      goPath(path) {
        this.$router.push(path);
      },
      exchangeCouponsUser(){

        const that = this;

        this.$refs['couponsExchangeFrm'].validate((valid) => {
          if (valid) {

            exchangeCoupons(this.couponsExchangeFrm.exchangeCode)
              .then(result => {

                if (result.succeed) {

                  that.$notify({
                    title: '兑换成功',
                    message: '优惠券已放入卡包~',
                    type: 'success'
                  });

                  if (that.couponTabActiveName == 'received'){
                      that.loadCouponsUsers();
                  } else {
                      that.couponTabActiveName = 'received';
                  }



                } else {
                  that.$notify({
                    title: '提示',
                    message: result.error_msg,
                    type: 'warning'
                  });
                }

              }).catch(err => {
              that.$notify({
                title: '提示',
                message: err,
                type: 'warning'
              });
            });

          } else {
            console.log('error submit!!');
            return false;
          }
        });

      },
    },
    watch: {
      tabActiveName(val){
        switch(val){
          case 'vip_card': {
              this.vipUserList.pager.total = 0;
              this.loadVipUsers();
          } break;
          case 'coupons': {
              this.couponsUserList.pager.total = 0;
              this.loadCouponsUsers();
          } break;
        }
      },
      vipCardTabActiveName(val){
        this.vipUserList.pager.total = 0;
        this.loadVipUsers();
      },
      couponTabActiveName(val){
        this.couponsUserList.pager.total = 0;
        this.loadCouponsUsers();
      },
    },
  }
</script>
