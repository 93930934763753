<template>
  <div :class="{'vipcard_item': true, 'sm': size_small, 'margin_bottom': margin_bottom_0 }">
    <div class="left">
      <span class="tag" v-if="tag_stop">已停用</span>
      <div class="content">
        <span class="discount" v-if="vip_card.vip_type=='discount'"><span>{{vip_card.discount_text}}</span>折</span>
        <span class="discount" v-if="vip_card.vip_type=='free'"><span>{{vip_card.discount_text}}</span></span>
        <span>{{vip_card.scope_text}}</span>
      </div>
    </div>
    <div class="right" :style="size_small ? 'max-width:314px;': ''">
      <div class="name text_ellipsis" :title="vip_card.name">{{vip_card.name}}</div>
      <div class="info">{{vip_card.scope_detail_text}}
        <span class="spot"></span>
        <span :style="{'color': expired_text_color}">{{vip_card.expired_text}}</span>
      </div>

      <div class="price" v-if="price_visible">
        <span class="discount" v-if="vip_card.promote_price > 0">¥ {{vip_card.promote_price}}</span>
        <span class="free" v-else>免费</span>
        <span class="original" v-if="vip_card.origin_price > 0">¥ {{vip_card.origin_price}}</span>
      </div>

      <div v-if="btn_buy">
        <el-button size="small" round class="btn_gold_common disabled" disabled
                   v-if="vip_card.is_user_valid">已购买
        </el-button>
        <el-button size="small" round class="btn_gold_common disabled" disabled
                   v-else-if="vip_card.available_num == 0">已售罄
        </el-button>
        <el-button size="small" round class="btn_gold_common"
                   v-else @click="$emit('buyVipCard')">立即购买
        </el-button>
      </div>

      <span v-if="expired_at">{{compExpiredText}}：{{compExpiredAt}}</span>

      <el-button type="text" class="detail_btn" v-if="btn_detail"
                 @click="$emit('showDetailModal')">详情</el-button>

    </div>

    <div class="disabled_layer" v-if="disabled"></div>
  </div>
</template>

<script>
  export default {
    props: {
      vip_card: Object,
      disabled: {
        type: Boolean,
        default: false
      },
      btn_detail: {
        type: Boolean,
        default: true
      },
      price_visible: {
        type: Boolean,
        default: true
      },
      btn_buy: {
        type: Boolean,
        default: true
      },
      expired_text: {
        type: String,
        default: '有效期至'
      },
      expired_at: {
        type: String,
        default: null
      },
      expired_text_color: {
        type: String,
        default: ''
      },
      size_small: {
        type: Boolean,
        default: false
      },
      margin_bottom_0: {
        type: Boolean,
        default: false
      },
      tag_stop: {
        type: Boolean,
        default: false
      },

    },
    data() {
      return {
        compExpiredText: this.expired_text,
        compExpiredAt: this.expired_at,
      }
    },
    mounted(){
        this.notifyDataChange();
    },
    updated() {
        this.notifyDataChange();
    },

    methods: {
      notifyDataChange(){

//        console.log('components-vip-card-> ', this.vip_card, this.expired_at);

        if (this.vip_card.failed_code == 8) {
          this.compExpiredText = '开始时间';
          this.compExpiredAt = this.vip_card.rights_start_local;
        } else {
          this.compExpiredText = this.expired_text;
          this.compExpiredAt = this.expired_at;
        }

      }
    }

  }
</script>

<style lang="scss" scoped>
  .vipcard_item {
    line-height: 1.15;
  }

  .margin_bottom {
    margin-bottom: 0;
  }
</style>
