<template>
  <div>
    <!-- 详情 -->
    <el-dialog
        :title="`${ modalTitle }详情`"
        v-model="modalVisible"
        width="800px">
        <!-- 可以使用span 和 span-map对象来控制栅格的大小 -->
        <el-description>
          <el-description-item label="名称：" :value="modalData.name" :span="24" labelWidth="100" />
          <el-description-item label="描述：" :value="modalData.desc" :span="24" labelWidth="100" />

          <el-description-item v-if="modalType === 'vip'" label="会员权益：" :value="modalData.discount" :span="12" labelWidth="100" />
          <el-description-item v-if="modalType === 'vip'" label="价格：" :value="`¥ ${ modalData.promote_price }`" :span="6" labelWidth="100" />
          <el-description-item v-if="modalType === 'vip' && modalData.origin_price != null" label="" :value="`¥ ${ modalData.origin_price }`" :span="6" labelWidth="50" style="text-decoration:line-through;color:#999;font-size: 12px;"/>

          <el-description-item v-if="modalType === 'coupons'" label="优惠方式：" :value="modalData.discount" :span="12" labelWidth="100" />
          <el-description-item v-if="modalType === 'coupons' && modalData.user_limit >0 " label="每人限领用：" :value="`${ modalData.user_limit }张`" :span="12" labelWidth="100" />
          <el-description-item v-if="modalType === 'coupons' && modalData.user_limit == 0 " label="每人限领用：" value="不限领取数量" :span="12" labelWidth="100" />

          <el-description-item label="使用有效期：" :value="modalData.expired_desc"  :span="24" labelWidth="100" />
          <el-description-item label="权益说明：" :value="modalData.rights_tips" :span="24" labelWidth="100" />
          <el-description-item label="领取须知：" :value="modalData.receive_tips" :span="24" labelWidth="100" />
          <el-description-item label="使用须知：" :value="modalData.instructions" :span="24" labelWidth="100" />
          <el-description-item label="适用范围：" :value="modalData.cur_scope" :span="24" labelWidth="100">
            <template #content>
              <div style="margin-bottom:10px;" v-if="modalData.cur_scope == 'all'">全部课程</div>
              <div style="margin-bottom:10px;" v-if="modalData.cur_scope == 'assign'">指定课程（{{data.cur_scope_detail.length}}个）</div>
              <el-table :data="data.cur_scope_detail" max-height="250" v-if="modalData.cur_scope == 'assign'"
                        style="width: 100%;border-left:1px solid #EBEEF5;border-right:1px solid #EBEEF5;">
                <el-table-column prop="name" label="课程名称"></el-table-column>
                <el-table-column prop="price" label="价格" width="180">
                  <template #default="scope">
                    ¥ {{ scope.row.price }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-description-item>
        </el-description>
    </el-dialog>
  </div>
</template>

<script>
  import ElDescription from 'components/common/el-descriptions/ElDescription'
  import ElDescriptionItem from 'components/common/el-descriptions/ElDescriptionItem'
  export default {
    components: {
      ElDescription,
      ElDescriptionItem
    },
    props: {
      visible: Boolean,
      title: String,
      data: Object,
      type: String
    },
    data() {
      return {
        modalVisible: this.visible,
        modalTitle: this.title,
        modalData: this.data,
        modalType: this.type,
      }
    },
    mounted() {
//      console.log(this.data);
    },
    watch: {
      visible(val) {
        this.modalVisible = val;
      },
      title(val) {
        this.modalTitle = val;
      },
      type(val) {
        this.modalType = val;
      },
      data(val) {
        this.modalData = val;
      },
      modalVisible(val) {
        this.$emit('update:visible', val);
      }
    }
  }
</script>
