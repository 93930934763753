<template>
  <div :class="{'coupon_item': true, 'sm': size_small }">
    <div class="left">
      <span class="tag" v-if="tag_stop">已停用</span>
      <div class="content">
        <span class="discount"><span>{{coupons.discount_strength}}</span>{{coupons.discount_text}}</span>
        <span>{{coupons.discount_limit}}</span>
      </div>
    </div>
    <div class="right" :style="size_small ? 'max-width:314px;': ''">
      <div class="name text_ellipsis" :title="coupons.name">{{coupons.name}}</div>
      <div class="info">{{coupons.scope_detail_text}}
        <span class="spot"></span>
        <span :style="{'color': expired_text_color}">{{coupons.expired_text}}</span>
      </div>
      <div class="info lightgray" v-if="user_limit_visible">{{coupons.user_limit_desc}}</div>

      <div v-if="btn_issue">

        <span v-if="coupons.user_limit > 0
                    && coupons.user_issued >= coupons.user_limit
                    && coupons.user_issued == coupons.user_used"></span>

        <el-button size="small" round class="btn_danger_common disabled" disabled
                   v-else-if="coupons.user_limit > 0
                              && coupons.user_issued >= coupons.user_limit
                              && coupons.user_issued > coupons.user_used">已领取
        </el-button>

        <el-button size="small" round class="btn_danger_common disabled" disabled
                   v-else-if="coupons.assets_limit > 0 && (coupons.assets_limit - coupons.issue_num) == 0">已被领完
        </el-button>

        <el-button size="small" round class="btn_danger_common" @click="$emit('issueCoupons')"
                   v-else>立即领取
        </el-button>

      </div>

      <span v-if="expired_at">{{compExpiredText}}：{{compExpiredAt}}</span>

      <el-button type="text" class="detail_btn" v-if="btn_detail"
                 @click="$emit('showDetailModal')">详情</el-button>

    </div>

    <div class="disabled_layer" v-if="disabled"></div>

  </div>

  <div class="info_sm" v-if="related_order">
    <span class="text_ellipsis">已在 {{related_order.order_sn}} 订单（{{related_order.state_text}}）中使用</span>
    <a @click="$emit('showRelatedOrderDetail')" style="cursor: pointer;">查看订单</a>
  </div>

</template>

<script>
  export default {
    props: {
      coupons: Object,
      disabled: {
        type: Boolean,
        default: false
      },
      btn_detail: {
        type: Boolean,
        default: true
      },
      user_limit_visible: {
        type: Boolean,
        default: true
      },
      btn_issue: {
        type: Boolean,
        default: true
      },
      expired_text: {
        type: String,
        default: '有效期至'
      },
      expired_at: {
        type: String,
        default: null
      },
      related_order: {
        type: Object,
        default: null
      },
      expired_text_color: {
        type: String,
        default: ''
      },
      size_small: {
        type: Boolean,
        default: false
      },
      tag_stop: {
        type: Boolean,
        default: false
      },

    },
    data() {
      return {
        compExpiredText: this.expired_text,
        compExpiredAt: this.expired_at,
      }
    },
    mounted(){
      this.notifyDataChange();
    },
    updated() {
      this.notifyDataChange();
    },

    methods: {
      notifyDataChange(){

//        console.log('components-coupons-> ', this.coupons);

        if(this.coupons.failed_code == 8){
            this.compExpiredText = '开始时间';
            this.compExpiredAt = this.coupons.time_start_local;
        } else {
            this.compExpiredText = this.expired_text;
            this.compExpiredAt = this.expired_at;
        }

      }
    }

  }
</script>

<style lang="scss" scoped>
  .info_sm {
    font-size:12px;
    display:flex;
    margin-bottom:20px;
    span {
      flex: 1;
    }
  }
</style>

